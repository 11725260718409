import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
import AdvanceSearch from './advanceSearch';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../../utility/url.utility';
import ViewTransaction from './viewTransaction';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Transaction',
  components: {
    AdvanceSearch,
    ViewTransaction,
    ModulesList
  },
  watch: {
    currentPage: function() {
      this.payload._page = this.currentPage - 1;
      this.payload._limit = this.perPage;
      this.getTransactionList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.payload._page = this.currentPage - 1;
      this.payload._limit = this.perPage;
      this.getTransactionList();
    }
  },
  data() {
    return {
      searchParameter: null,
      transactionDetails: null,
      incompleteTransactionObj: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      loader: false,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      isAdvanceSearchEnable: false,
      showViewTransaction: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      moduleName:null,
      showModuleForm:false,
      moduleId:null,
      legalEntity: {
        value: null,
        text: null
      },
      trxNum: null,
      trxStatus: {
        value: null,
        text: null
      },
      transactionList: [],
      transactionFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'initiate_request',
          label: 'Request',
          stickyColumn: true
        },
        {
          key: 'trx_number',
          label: 'Transaction Num'
        },
        {
          key: 'trx_status_meaning',
          label: 'Transaction Status'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'trx_date',
          label: 'Transaction Date'
        },
        {
          key: 'customer'
        },
        {
          key: 'customer_site_id'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate',
          label: 'Exc Rate'
        },
        {
          key: 'trx_amount'
        },
        {
          key: 'irn',
          label: 'IRN'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'value_date',
          label: 'VL Date'
        },
        {
          key: 'request_id',
          label: 'Request Num'
        },
        {
          key: 'email',
          label: 'Resend Email'
        },
      

      ],
      trxHdrId: null,
      selectAllCheckBox: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showValueSetModal: false,
      vsetCode: null
    };
  },
  validations: {
    moduleId: { required }
  },
  mounted() {
    // this.advanceSearch(null);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showViewTransaction &&
          !this.isAdvanceSearchEnable
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'receivables/getArTransactionList',
            'Transaction',
            () => (this.loader = false)
          );
        }
        if (
          actionName === 'save' &&
          !this.showViewTransaction &&
          !this.isAdvanceSearchEnable
        ) {
          this.generateTransactionRequest();
        }
        if (
          actionName === 'print' &&
          !this.showViewTransaction &&
          !this.isAdvanceSearchEnable
        ) {
          this.transactionDetails.doc_file
            ? this.downloadLog()
            : alert('File Not Available. Try After Sometime');
        }
        if (
          actionName === 'approval' &&
          !this.showViewTransaction &&
          !this.isAdvanceSearchEnable
        ) {
          this.changeTransactionStatus();
        }
      }
    });
  },
  methods: {
    downloadLog() {
      window.open(
        this.dowloadReport + '/' + this.transactionDetails.request_id,
        '_blank'
      );
    },
    generateTransactionRequest() {
      if (!this.transactionDetails.request_id) {
        this.loader = true;
        this.$store
          .dispatch(
            'receivables/addArTransactionDetails',
            this.transactionDetails.trx_hdr_id
          )
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              if (response.data.data) {
                this.getTransactionList();
              }
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Request already generated!');
      }
    },
    getTransactionList() {
      if (this.searchParams) {
        this.payload = this.searchParams;
        this.moduleId=this.searchParams.module_id;
      } else {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          module_id: this.moduleId,
          le_id: this.legalEntity.value,
          trx_num: this.trxNum,
          trx_status_vset: this.trxStatus.value,
        };
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
      this.loader = true;
      this.$store
        .dispatch('receivables/getArTransactionList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.transactionList = resp.data.data.page;
            this.transactionList = this.transactionList.map(data => {
              data.select = false;
              data.email = 'Resend';
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
      }
    },
    advanceSearchModal() {
      this.isAdvanceSearchEnable = true;
    },
    searchBill(payload) {
      if (payload) {
        this.searchParameter = payload;
      } else {
        this.searchParameter = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          with_unit: true
        };
      }
      this.loader = true;
      this.isAdvanceSearchEnable = false;
      this.$store
        .dispatch('fms/getFMSTransaction', this.searchParameter)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.transactionList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(items) {
      this.trxHdrId = items.trx_hdr_id;
      this.showViewTransaction = true;
    },
    mainSearch() {
      this.searchParams = null;
      this.getTransactionList();
    },
    advanceSearch(params) {
      this.searchParams = params;
      this.isAdvanceSearchEnable = false;
      this.getTransactionList();
    },
    checkUncheckAll() {
      this.transactionList = this.transactionList.map(data => {
        data.select = this.selectAllCheckBox;
        return data;
      });
    },
    changeTransactionStatus() {
      const checkTrxType = this.transactionList.filter(key => key.select);
      const details = checkTrxType.map(key => {
        return {
          trx_hdr_id: key.trx_hdr_id,
          trx_status: key.trx_status_code
        };
      });
      const payload = {
        ar_trx_details: details
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/setArTransactionToComplete', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getTransactionList();
            }
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    initiateReqeustFunc(item) {
      this.transactionDetails = Object.assign({}, item);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_VALIDATION) {
        this.trxStatus.text = item.value_meaning;
        this.trxStatus.value = item.value_code;
      }
    },
    resendEmail(trx_hdr_id) {
      this.loader = true;
      this.$store
        .dispatch('receivables/resendEmailForLeaseInvoices', trx_hdr_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.trxStatus.value) {
        this.trxStatus = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: null,
          text: null
        };
      }
    },
    openModuleForm(flag) {
      this.showModuleForm = flag;
    },
    selectedModuleData(module) {
      this.moduleName = module.module_name;
      this.moduleId = module.module_id;
      this.showModuleForm = false;
    },
    clearSearch() {
      this.moduleName = null;
      this.moduleId = null;
      this.legalEntity = {
        value: null,
        text: null
      };
      this.trxNum = '';
      this.trxStatus = {
        value: null,
        text: null
      };
      this.transactionList = [];
      this.totalRows=null;
    },
    onDeletion() {
        this.showViewTransaction = false;
        this.getTransactionList();
    },
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('receivables/saveAdvSearchFilters', null);
  }
};
